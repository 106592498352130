import React, { useState } from 'react';
import Nav from './Nav';
import { Link } from 'gatsby';
import Timer from './Timer';

// import logo from '../assets/images/logo.png';
import logo from '../assets/images/logo_mahi.png';

const styles = {
  logo: {
    padding: '0px 1em',
    marginTop: '.5em',
    width: 'auto',
    height: '4em',
  },
};

export default function SideBar({ fullMenu, liveScoring }) {
  const [headerOpen, toggleHeader] = useState(false);
  return (
    <>
      <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
        <h1>
          <Link to="/">
            <img style={styles.logo} src={logo} alt="" />
          </Link>
        </h1>

        <nav>
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              toggleHeader(!headerOpen);
            }}
            className="menuToggle"
          >
            <span>Menu</span>
          </a>
        </nav>
      </header>
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </>
  );
}
