module.exports = {
  siteTitle: 'CCO Showdown - Meat Fish Edition', // <title>
  manifestName: 'CCO Showdown',
  manifestShortName: 'Showdown', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#0bd3d3',
  manifestThemeColor: '#f890e7',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-solidstate/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'CCO Showdown',
  subHeading: 'Represent your brand and may the best center console win! ',
  // social
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      tag: "@CenterConsolesOnly",
      url: 'https://instagram.com/centerconsolesonly',
    },
    {
      icon: 'fa-youtube',
      name: 'Youtube',
      tag: "@CenterConsolesOnly",
      url: 'https://youtube.com/centerconsolesonly',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      tag: "@CenterConsolesOnly1",
      url: 'https://facebook.com/centerconsolesonly1',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      tag: 'Email Us',
      url: 'mailto:support@centerconsolesonly.net',
    },
  ],
  phone: '786-205-1945',
  address: '4658 SW 74th Ave, Miami, FL 33155',
};
