import React, { useState } from 'react';
import config from '../../config';

export default () => {
  const [submitted, setSubmitted] = useState(false);
  const [state, setState] = React.useState({});

  function encode(data) {
    return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
  }

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => setSubmitted(true))
      .catch(error => alert(error));
  };
  return (
    <section id="footer">
      <div className="inner">
        <h2 className="major">Get in touch</h2>
        <p>
          Please feel free and reach out with any questions about entering or
          sponsoring the CCO Showdown Fishing Tournament!
        </p>

        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          {submitted ? (
            <div style={{ marginTop: '25%' }}>
              Your message was sent. Someone from our team will be with you
              shortly!
            </div>
          ) : (
            <>
              <div className="fields">
                <div className="field">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="field">
                  <label htmlFor="mail">Email</label>
                  <input
                    type="email"
                    name="mail"
                    id="mail"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="field">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="field">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    rows="3"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <ul className="actions">
                <li>
                  <input type="submit" value="Send Message" />
                </li>
              </ul>
            </>
          )}
        </form>

        <ul style={{paddingTop: "35px"}} className="contact">
          <li className="fa-home"><strong>{config.address}</strong></li>

          <li className="fa-phone"><strong>{config.phone}</strong></li>

          {config.socialLinks.map(social => {
            const { icon, tag, url } = social;
            return (
              <li className={`${icon}`} key={url}>
                <a href={url} target="_blank" rel="noreferrer"><strong>{tag}</strong></a>
              </li>
            );
          })}
        </ul>
        <ul className="copyright">
          <li>&copy; CCO Showdown LLC. All rights reserved.</li>
        </ul>
      </div>
    </section>
  );
};
